<template>
  <svg
    id="Gruppe_84"
    data-name="Gruppe 84"
    xmlns="http://www.w3.org/2000/svg"
    width="46.662"
    height="49.757"
    viewBox="0 0 46.662 49.757"
  >
    <path
      id="Pfad_36"
      data-name="Pfad 36"
      d="M181.1,7.665a1.948,1.948,0,0,1,1.288,2.188c-.037.4.006.81-.021,1.214a1.649,1.649,0,0,1-1.619,1.56c-.533.016-1.066,0-1.641,0v1.927a21.637,21.637,0,0,1,10.426,4.74c.4-.421.792-.808,1.15-1.222a.531.531,0,0,0,.05-.423,2.618,2.618,0,0,1,1.07-3.062,2.588,2.588,0,0,1,3.215.392q1.663,1.6,3.263,3.263a2.579,2.579,0,0,1-3.245,3.986c-.054-.033-.111-.064-.158-.091l-1.391,1.431a21.358,21.358,0,0,1,2.846,18.679A21.131,21.131,0,0,1,187.9,53.668a21.57,21.57,0,0,1-29.846-30.153l-1.439-1.364a2.758,2.758,0,0,1-1.991.5,2.539,2.539,0,0,1-1.659-4.056,50.179,50.179,0,0,1,3.833-3.852,2.546,2.546,0,0,1,4.02,2.671.792.792,0,0,0,.267.954,12.823,12.823,0,0,1,.874.944,21.735,21.735,0,0,1,10.484-4.76c0-.531,0-1.031,0-1.531,0-.11-.013-.219-.024-.385-.518,0-1.015,0-1.512,0a1.676,1.676,0,0,1-1.773-1.761c-.008-.34.025-.683-.008-1.02a1.94,1.94,0,0,1,1.285-2.187ZM175.708,54.1a18.238,18.238,0,1,0-18.175-18.289A18.241,18.241,0,0,0,175.708,54.1Zm-1.579-39.777h3.243V12.659h-3.243ZM157.9,20.967l1.191,1.182,1.627-1.763-1.094-1.122Zm32.89-.58,1.6,1.722,1.239-1.147L191.9,19.276Z"
      transform="translate(-152.415 -7.665)"
      fill="currentColor"
    />
    <path
      id="Pfad_37"
      data-name="Pfad 37"
      d="M230.577,160.919a18.238,18.238,0,1,1,18.275-18.2A18.228,18.228,0,0,1,230.577,160.919Zm11.179-30.495a16.59,16.59,0,0,0-10.3-4.286c0,.268,0,.475,0,.683-.005.583.008,1.167-.024,1.748a.809.809,0,1,1-1.617-.011c-.031-.468-.019-.938-.022-1.408,0-.331,0-.662,0-1.016a16.56,16.56,0,0,0-10.28,4.281c.584.575,1.135,1.1,1.662,1.645a.815.815,0,0,1-.165,1.378.848.848,0,0,1-1.049-.255c-.529-.538-1.049-1.086-1.585-1.644a16.578,16.578,0,0,0-4.284,10.312c.262,0,.469,0,.676,0a15.051,15.051,0,0,1,1.842.048.951.951,0,0,1,.654.525.974.974,0,0,1-.2.817,1.237,1.237,0,0,1-.793.253c-.706.033-1.415.011-2.177.011a16.6,16.6,0,0,0,4.294,10.311c.547-.572,1.063-1.128,1.6-1.665a.832.832,0,1,1,1.173,1.159c-.533.541-1.088,1.06-1.661,1.615a16.529,16.529,0,0,0,10.3,4.279c0-.255,0-.431,0-.607,0-.583-.009-1.166.015-1.748a.806.806,0,0,1,.824-.884c.481,0,.779.325.813.9.017.291.011.583.012.874,0,.478,0,.956,0,1.478a16.574,16.574,0,0,0,10.26-4.26c-.1-.1-.16-.174-.227-.242-.479-.482-.994-.936-1.423-1.459a1.1,1.1,0,0,1-.209-.865.922.922,0,0,1,.652-.527,1.171,1.171,0,0,1,.795.342c.542.5,1.033,1.058,1.564,1.613a16.585,16.585,0,0,0,4.292-10.307h-.536c-.583,0-1.166,0-1.749-.008a.85.85,0,0,1-.929-.619.8.8,0,0,1,.847-1.02c.773-.02,1.547,0,2.369,0a16.544,16.544,0,0,0-4.248-10.276c-.129.121-.247.228-.361.341a17.549,17.549,0,0,1-1.356,1.319,1.139,1.139,0,0,1-.873.2.846.846,0,0,1-.508-.613,1.3,1.3,0,0,1,.349-.84C240.648,131.45,241.2,130.967,241.756,130.424Z"
      transform="translate(-207.284 -114.48)"
      fill="#fff"
    />
    <path
      id="Pfad_38"
      data-name="Pfad 38"
      d="M406.957,67.872V66.2H410.2v1.668Z"
      transform="translate(-385.242 -61.21)"
      fill="#fff"
    />
    <path
      id="Pfad_39"
      data-name="Pfad 39"
      d="M216.7,145.334l1.725-1.7,1.094,1.122-1.627,1.763Z"
      transform="translate(-211.213 -132.032)"
      fill="#fff"
    />
    <path
      id="Pfad_40"
      data-name="Pfad 40"
      d="M602.245,144.881l1.111-1.111,1.727,1.685-1.239,1.147Z"
      transform="translate(-563.871 -132.159)"
      fill="#fff"
    />
    <path
      id="Pfad_41"
      data-name="Pfad 41"
      d="M259.842,148.561c-.561.543-1.108,1.026-1.593,1.564a1.3,1.3,0,0,0-.349.84.846.846,0,0,0,.508.613,1.139,1.139,0,0,0,.873-.2,17.53,17.53,0,0,0,1.356-1.319c.113-.112.232-.219.361-.341A16.544,16.544,0,0,1,265.246,160c-.822,0-1.6-.015-2.369,0a.8.8,0,0,0-.847,1.02.85.85,0,0,0,.929.619c.583.013,1.166.006,1.749.008h.536a16.585,16.585,0,0,1-4.292,10.307c-.53-.555-1.022-1.111-1.564-1.613a1.171,1.171,0,0,0-.795-.342.922.922,0,0,0-.652.527,1.1,1.1,0,0,0,.209.865c.429.523.944.977,1.423,1.459.068.068.132.14.228.242a16.574,16.574,0,0,1-10.26,4.26c0-.522,0-1,0-1.478,0-.291,0-.583-.012-.874-.034-.572-.332-.893-.813-.9a.806.806,0,0,0-.824.884c-.024.582-.012,1.166-.015,1.748,0,.176,0,.352,0,.607a16.528,16.528,0,0,1-10.3-4.279c.573-.555,1.128-1.074,1.661-1.615a.832.832,0,1,0-1.173-1.159c-.536.536-1.052,1.093-1.6,1.665a16.6,16.6,0,0,1-4.294-10.311c.762,0,1.471.022,2.177-.011a1.237,1.237,0,0,0,.793-.253.974.974,0,0,0,.2-.817.951.951,0,0,0-.654-.525,15.055,15.055,0,0,0-1.842-.048c-.207,0-.414,0-.676,0a16.578,16.578,0,0,1,4.284-10.312c.536.557,1.056,1.105,1.585,1.644a.848.848,0,0,0,1.049.255.815.815,0,0,0,.165-1.378c-.527-.547-1.079-1.07-1.662-1.645a16.56,16.56,0,0,1,10.28-4.281c0,.354,0,.685,0,1.016,0,.469-.008.94.022,1.408a.809.809,0,1,0,1.617.011c.032-.581.019-1.165.024-1.748,0-.207,0-.414,0-.683A16.59,16.59,0,0,1,259.842,148.561Zm-10.3,5.167h-.008c0-.7,0-1.392,0-2.089,0-.582.009-1.166-.038-1.745a.714.714,0,0,0-.761-.67.76.76,0,0,0-.816.669,2.792,2.792,0,0,0-.031.483c0,2.267-.011,4.533-.006,6.8a.511.511,0,0,1-.362.581,3.224,3.224,0,0,0-2.048,3.657,3.295,3.295,0,0,0,6.477,0,3.232,3.232,0,0,0-2.048-3.659.481.481,0,0,1-.364-.53C249.55,156.059,249.542,154.893,249.542,153.728Z"
      transform="translate(-225.37 -132.617)"
      fill="currentColor"
    />
    <path
      id="Pfad_42"
      data-name="Pfad 42"
      d="M391.464,206.832c0,1.166.008,2.331-.006,3.5a.481.481,0,0,0,.364.53,3.232,3.232,0,0,1,2.048,3.659,3.294,3.294,0,0,1-6.477,0,3.224,3.224,0,0,1,2.048-3.657.511.511,0,0,0,.362-.581c-.005-2.267,0-4.533.006-6.8a2.8,2.8,0,0,1,.031-.483.76.76,0,0,1,.816-.669.714.714,0,0,1,.761.67c.047.579.034,1.163.038,1.745.005.7,0,1.392,0,2.089Zm.818,7.053a1.651,1.651,0,1,0-1.606,1.669A1.678,1.678,0,0,0,392.282,213.885Z"
      transform="translate(-367.292 -185.721)"
      fill="#fff"
    />
    <path
      id="Pfad_43"
      data-name="Pfad 43"
      d="M409.965,320.324a1.651,1.651,0,1,1-1.7-1.631A1.672,1.672,0,0,1,409.965,320.324Z"
      transform="translate(-384.974 -292.16)"
      fill="currentColor"
    />
  </svg>
</template>
